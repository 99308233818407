import digiClock from "../assets/img/products/digi-clock.svg";
import onePDF from "../assets/img/products/one-pdf.svg";
const INFO = {
	main: {
		title: "Mamminasa Tech",
		name: "Mamminasa tech",
		email: "lawang.mallawangan@gmail.com",
		logo: "../mamminasa-tech-192.png",
	},

	products: [
		{
			title: "Digi Clock",
			description:
				"Aplikasi yang memungkinkan Anda mengapresiasi keindahan waktu dengan gaya sederhana dan minimalis. Aplikasi ini sangat cocok untuk pelajar, pekerja kantoran, penggemar fotografi, dan siapa saja yang ingin memanfaatkan waktunya semaksimal mungkin.",
			logo: digiClock,
			linkText: "Lihat Detail",
			link: "https://digiclock.mamminasatech.com",
		},
		{
			title: "One PDF",
			description:
				"Rasakan pengalaman melihat PDF tanpa hambatan dengan Satu PDF. Antarmuka kami yang bersih dan intuitif membuat membaca PDF di perangkat seluler Anda menjadi mudah.",
			logo: onePDF,
			linkText: "Lihat Detail",
			link: "/products/one-pdf",
		},
	],
}
 export default INFO;