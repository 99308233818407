import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width="44" height="40" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.562 28.112H4.778C3.87506 28.1083 3.01004 27.7486 2.37081 27.1108C1.73159 26.4731 1.36979 25.6089 1.364 24.706V8.77601C1.36716 7.87259 1.72724 7.00702 2.36578 6.36791C3.00432 5.72881 3.86957 5.36797 4.773 5.36401H38.557C39.4602 5.36796 40.3252 5.72849 40.9639 6.36714C41.6025 7.00579 41.9631 7.87084 41.967 8.77401V24.7C41.9636 25.603 41.6037 26.4681 40.9656 27.1071C40.3276 27.7461 39.463 28.1073 38.56 28.112H38.562Z" fill="#F40051"/>
      <path d="M38.559 4H4.778C3.51171 4.00185 2.29778 4.50556 1.40219 5.40078C0.506603 6.296 0.00238039 7.50971 0 8.776V24.698C0.00211534 25.9646 0.50619 27.1786 1.40178 28.0742C2.29737 28.9698 3.51145 29.4739 4.778 29.476H18.285V33.798H14.359C14.1783 33.7988 14.0052 33.8711 13.8776 33.999C13.75 34.127 13.6783 34.3003 13.678 34.481V35.847C13.6783 36.0277 13.75 36.201 13.8776 36.329C14.0052 36.4569 14.1783 36.5292 14.359 36.53H28.978C29.1588 36.5292 29.332 36.457 29.4597 36.329C29.5875 36.2011 29.6595 36.0278 29.66 35.847V34.481C29.6595 34.3002 29.5875 34.1269 29.4597 33.999C29.332 33.871 29.1588 33.7988 28.978 33.798H25.052V29.476H38.56C39.8262 29.4734 41.0398 28.969 41.9349 28.0735C42.8301 27.178 43.3339 25.9642 43.336 24.698V8.776C43.3336 7.50988 42.8295 6.29632 41.9342 5.40113C41.0388 4.50595 39.8251 4.00211 38.559 4ZM22.321 33.8H21.013V29.478H22.321V33.8ZM40.604 24.7C40.5988 25.241 40.3818 25.7585 39.9995 26.1414C39.6172 26.5242 39.1 26.742 38.559 26.748H4.778C4.23626 26.7428 3.71816 26.5254 3.33489 26.1425C2.95162 25.7596 2.73373 25.2417 2.728 24.7V8.778C2.73373 8.23671 2.95137 7.71921 3.33424 7.33653C3.71711 6.95385 4.2347 6.73646 4.776 6.731H38.558C39.0988 6.73724 39.6156 6.95498 39.9978 7.33757C40.3801 7.72017 40.5973 8.23722 40.603 8.778L40.604 24.7Z" fill="#0B093B"/>
    </svg>
  );
}

export default SvgComponent;
