import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing";
import NotFound from "./screens/404";
import Products from "./screens/Products";
import Services from "./screens/Services";
import ReactGA from "react-ga4";
import { TRACKING_ID } from "./data/tracking.js";
import { Route, Routes } from "react-router-dom";
import OnePdf from "./screens/detailProducts/OnePdf/OnePdf";
import Toc from "./screens/detailProducts/OnePdf/Toc";

export default function App() {
  useEffect(() => {
		if (TRACKING_ID !== "") {
			ReactGA.initialize(TRACKING_ID);
		}
	}, []);
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/products" element={<Products />} />
          <Route path="/services" element={<Services />} />
          <Route path="/products/one-pdf" element={<OnePdf />} />
          <Route path="/products/one-pdf/toc" element={<Toc />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
    </>
  );
}

