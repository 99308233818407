import React, { useEffect } from "react";
import HeaderProduct from "./Sections/Header";
import { Helmet } from "react-helmet";
import SEO from "../../../data/seo";
import About from "./Sections/About";
import Feature from "./Sections/Feature";
import Footer from "./Sections/Footer";
import TopNavbar from "./Nav/TopNavbar";

const OnePdf = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

  const currentSEO = SEO.find((item) => item.page === "one-pdf");

  return (
    <React.Fragment>
			<Helmet>
				<title>{currentSEO.title}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
				<meta property="og:title" content={currentSEO.title} />
				<meta property="og:description" content={currentSEO.description} />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:image" content={currentSEO.image} />
				<meta property="og:type" content="website" />
			</Helmet>
			<TopNavbar />
    	<HeaderProduct />
			<About />
			<Feature />
			<Footer />
    </React.Fragment>
  )
};

export default OnePdf;