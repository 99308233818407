import React, { useEffect } from "react";
import TopNavbar from "../components/Nav/TopNavbar";
import styled from "styled-components";
import INFO from "../data/user";
import "./styles/404.css";

const NotFound = () => {

  useEffect(() => {
		document.title = `404 | ${INFO.main.title}`;
	}, []);

  return (
    <React.Fragment>
      <Wrapper>
      <TopNavbar page={"404"}/>
      <NotFoundWrapper>
					<div className="notfound-container">
						<div className="notfound-message">
							<div className="notfound-title">
								Oops!
							</div>
							<div className="not-found-message">
								We can't seem to find the page you're looking
								for.
								<br />
								The requested URL "{window.location.href}" was
								not found on this server.
							</div>
							<a href="/" className="not-found-link">
								Go back to the home page
							</a>
						</div>
					</div>
			</NotFoundWrapper>
      </Wrapper>
    </React.Fragment>

  )
};

export default NotFound;

const Wrapper = styled.section`
  width: 100%;
  `;
const NotFoundWrapper = styled.div`
  overflow: hidden;
  height: 100vh;
  `;
