import React from "react";
import styled from "styled-components";

const About = () => {
  return (
    <Wrapper id="about">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <Title className="font40 extraBold">The Best Mobile PDF Reader App</Title>
          <Desc className="font15">One PDF is a mobile app specifically designed to help you read PDF documents easily and comfortably. With its simple and user-friendly interface, One PDF ensures an optimal reading experience for every user.</Desc>
        </div>
      </div>

    </Wrapper>
  )
};

const Wrapper = styled.div`
  width: 100%;
`;

const Title = styled.h1`
  margin: 0 20px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const Desc = styled.p`
  max-width: 50%;
  margin: 0 20px;
  @media (max-width: 860px) {
    max-width: 100%;
    text-align: center;
  }
`;
export default About;