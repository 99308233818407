import React from "react";
import styled from "styled-components";
import AllProducts from "../Elements/AllProducts";

export default function Products() {
  return (
    <Wrapper id="products">
      <div className="whiteBgt">
        <div className="container">
          <h1 className="font40 extraBold">Produk Digital Mamminasa Tech </h1>
          <p className="font13">
          Temukan aplikasi mobile dan perangkat lunak di Mamminasa Tech.
          <br/>Unduh langsung dari Play Store dan App Store. Jelajahi koleksi
          <br/>aplikasi website, dan alat produktivitas terbaik kami.
          <br/>Mulai unduhan sekarang dan nikmati kemudahan teknologi digital dari Mamminasa Tech.
          </p>
          <AllProducts />
        </div>
      </div>
    </Wrapper>

  )
}

const Wrapper = styled.section`
  width: 100%;
`;