import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";

export default function Services({page = 'home'}) {
  return (
    <Wrapper id="services">
      {page === 'home' && (
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      )}
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Layanan Kami</h1>
            <p className="font13">
            Mamminsatech menawarkan layanan pembuatan aplikasi mobile (Android/iOS), pengembangan website,
            <br/>dan desain UI/UX profesional di Indonesia, khususnya di Makassar, Sulawesi Selatan.
            <br/>Kami menyediakan solusi digital yang inovatif dan efisien untuk membantu bisnis Anda tumbuh
            <br/>dan bersaing di era digital. Dengan tim ahli yang berpengalaman, kami memastikan setiap proyek
            <br/>memenuhi standar kualitas tertinggi dan memberikan pengalaman pengguna yang luar biasa.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="row textCenter">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="Web Development"
                subtitle="Kami membuat website yang menarik dan responsif, yang bekerja dengan baik di semua perangkat."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="mobile"
                title="Mobile Development"
                subtitle="Kami membuat aplikasi mobile yang keren dan mudah digunakan untuk iOS dan Android."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title="UI/UX Design"
                subtitle="Kami mendesain tampilan yang menarik dan mudah digunakan untuk aplikasi dan website Anda."
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h2 className="font40 extraBold">Mengapa memilih kami ?</h2>
                <p className="font12">
                Mamminasa Tech memberikan beberapa keunggulan dan layanan jangka panjang untuk project anda. Design yang responsive, keamanan, maintenance dan performa yang baik.
                </p>
                <Link to="contact" smooth={true} duration={1000}>
                  <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                    <div style={{ width: "190px" }}>
                      <FullButton title="Hubungi Kami" />
                    </div>
                  </ButtonsRow>
                </Link>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                      <p className="font28">Performa</p>
                    </AddImgWrapp1>
                    <AddImgWrapp2 className="flexCenter">
                      <img src={AddImage2} alt="office" />
                      <p className="font28">Pemeliharaan</p>
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3 className="flexCenter">
                      <img src={AddImage3} alt="office" />
                      <p className="font28">Keamanan</p>
                    </AddImgWrapp3>
                    <AddImgWrapp4 className="flexCenter">
                      <img src={AddImage4} alt="office" />
                      <p className="font28">Responsif</p>
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  justify-content: center;
  @media (max-width: 860px) {
    flex-direction: column;
  }

`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
    height: auto;
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
    height: auto;
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
    height: auto;
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
    height: auto;
  }
`;