import React from "react";
import styled from "styled-components";
import { faFileImage, faCompressAlt, faTrophy, faLock, faLayerGroup} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OnePdfFeature from "../../../../assets/img/onepdf-feature.png";


const Feature = () => {
  return (
    <Wrapper id="feature">
      <div className="whiteBg">
        <div className="container" style={{ padding: "50px 0" }}>
          <Title className="font40 extraBold">Feature</Title>
          <Subtitle className="font18">One PDF has features that can help to open PDF files easily</Subtitle>
        </div>
        <Container className="row" >
        <LeftSide>
          <Item>
            <FontAwesomeIcon color="#FF6666" size="3x" icon={faFileImage} />
            <Fitur>
              <p className="font18 extraBold">Image to PDF Converter</p>
              <p className="font15">Easily convert your images into high-quality PDF documents 
                <br/>with just a few taps.
              </p>
            </Fitur>
          </Item>
          <Item>
            <FontAwesomeIcon color="#FF6666" size="3x" icon={faCompressAlt} />
            <Fitur>
              <p className="font18 extraBold">Compress PDF</p>
              <p className="font15">Reduce the size of your PDF files without compromising quality,
                <br/>perfect for sharing and saving storage.
              </p>
            </Fitur>
          </Item>
          <Item>
            <FontAwesomeIcon color="#FF6666" size="3x" icon={faLock} />
            <Fitur>
              <p className="font18 extraBold">Protect PDF</p>
              <p className="font15">Secure your documents with passwords to ensure your files
                <br/>remain private and safe.
              </p>
            </Fitur>
          </Item>
          <Item>
            <FontAwesomeIcon color="#FF6666" size="3x" icon={faLayerGroup} />
            <Fitur>
              <p className="font18 extraBold">Merge and Manage PDFs</p>
              <p className="font15">Combine multiple PDF files into a single document and
                <br/>manage them with ease in one place.
              </p>
            </Fitur>
          </Item>
          <Item>
            <FontAwesomeIcon color="#FF6666" size="3x" icon={faTrophy} />
            <Fitur>
              <p className="font18 extraBold">Fast and Stable Performance</p>
              <p className="font15">One PDF combines simple navigation, stable performance, and 
                <br/>wide compatibility to deliver an excellent user experience.
              </p>
            </Fitur>
          </Item>
        </LeftSide>

          <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={OnePdfFeature} alt="office" style={{zIndex: 9}} />
        </ImageWrapper>
      </RightSide>
        </Container>
      </div>
    </Wrapper>
  )
};

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 100px;
`;

const Container = styled.div`
  justify-content: center;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0px 30px;
`;

const Title = styled.h1`
  text-align: center;
`;

const Subtitle = styled.p`
  text-align: center;
  margin: 0 20px;
  @media (max-width: 860px) {
    max-width: 100%;
  }
`;

const LeftSide = styled.div`
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const Item = styled.div`
  display: flex;
  margin: 0 0 50px 0;
`;

const Fitur = styled.div`
  margin: 0 20px;
`;

const RightSide = styled.div`
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  width: 500px;
  height: auto;
  @media (max-width: 560px) {
    width: 60%;
    height: auto;
  }
`;

export default Feature;