import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width="29" height="40" viewBox="0 0 29 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M25.5882 6.76465C26.8877 6.76465 27.9412 7.8181 27.9412 9.11759V33.2352C27.9412 36.8088 25.0442 39.7058 21.4706 39.7058H7.35294C6.05345 39.7058 5 38.6524 5 37.3529C5 36.0534 6.05345 34.9999 7.35294 34.9999H21.4706C22.4452 34.9999 23.2353 34.2099 23.2353 33.2352V9.11759C23.2353 7.8181 24.2887 6.76465 25.5882 6.76465Z" fill="#47B5FF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.52941C0 1.58017 1.58017 0 3.52941 0H24.7059C26.6551 0 28.2353 1.58017 28.2353 3.52941V36.4706C28.2353 38.4198 26.6551 40 24.7059 40H3.52941C1.58017 40 0 38.4198 0 36.4706V3.52941ZM3.52941 2.35294C2.87967 2.35294 2.35294 2.87967 2.35294 3.52941V36.4706C2.35294 37.1203 2.87967 37.6471 3.52941 37.6471H24.7059C25.3556 37.6471 25.8824 37.1203 25.8824 36.4706V3.52941C25.8824 2.87966 25.3556 2.35294 24.7059 2.35294H3.52941Z" fill="#0B093B"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4.70584C5 4.21853 5.39504 3.82349 5.88235 3.82349H9.41176C9.89908 3.82349 10.2941 4.21853 10.2941 4.70584C10.2941 5.19315 9.89908 5.58819 9.41176 5.58819H5.88235C5.39504 5.58819 5 5.19315 5 4.70584Z" fill="#0B093B"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4118 4.70584C14.4118 4.21853 14.8068 3.82349 15.2941 3.82349H21.7647C22.252 3.82349 22.6471 4.21853 22.6471 4.70584C22.6471 5.19315 22.252 5.58819 21.7647 5.58819H15.2941C14.8068 5.58819 14.4118 5.19315 14.4118 4.70584Z" fill="#0B093B"/>
      <path d="M17.0588 32.3529C17.0588 33.6524 16.0054 34.7059 14.7059 34.7059C13.4064 34.7059 12.3529 33.6524 12.3529 32.3529C12.3529 31.0534 13.4064 30 14.7059 30C16.0054 30 17.0588 31.0534 17.0588 32.3529Z" fill="#47B5FF"/>
  </svg>

  );
}

export default SvgComponent;
