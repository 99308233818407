import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import INFO from "../../../data/user";
import SEO from "../../../data/seo";

const Toc = () => {
  const currentSEO = SEO.find((item) => item.page === "one-pdf");
  return (
    <React.Fragment>
      <Helmet>
				<title>{`Toc One PDF | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>
      <Container>
        <Title>Terms & Conditions One PDF</Title>
        <Content className="whiteBg">
          <div className="container">
            <Section>
              <h1 className="font15 extraBold">Welcome to One PDF By Mamminasa Tech</h1>
              <Paragraph className="font15">
              One PDF is a mobile app specifically designed to help you read PDF documents easily and comfortably. With its simple and user-friendly interface, One PDF ensures an optimal reading experience for every user.</Paragraph>
            </Section>
            <Section>
              <Paragraph className="font15 extraBold">Privacy Policy</Paragraph>
              <Paragraph className="font14 extraBold">Last Updated: 19th July 2024</Paragraph>
            </Section>
            <Section>
              <Paragraph className="font15">We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and protect the information you provide when using the One PDF app.</Paragraph>
            </Section>
            <Section>
              <Paragraph className="font14 extraBold">1. Information We Collect</Paragraph>
              <Paragraph className="font14">
              One PDF requests the following permissions solely to access and display PDF files on your device:
              <ul>
                <li>READ_EXTERNAL_STORAGE</li>
                <li>MANAGE_DOCUMENTS</li>
              </ul>
            </Paragraph>
            </Section>
            <Section>
            <Paragraph className="font14 extraBold">2. Use of Permissions</Paragraph>
            <Paragraph className="font14">
              These permissions are used exclusively for the following purposes:
              <ul>
                <li>READ_EXTERNAL_STORAGE: Allows the app to read PDF files stored on your device's external storage.</li>
                <li>MANAGE_DOCUMENTS: Allows the app to access and manage PDF documents stored on your device.</li>
              </ul>
            </Paragraph>
            <Paragraph className="font14">
              No data is collected or stored by One PDF from the use of these permissions. These permissions are solely used to access and display PDF files as needed by the user.
            </Paragraph>
          </Section>
          <Section>
            <Paragraph className="font14 extraBold">3. Data Security</Paragraph>
            <Paragraph className="font14">
              We take the security of your information seriously. One PDF does not collect, store, or share any personal data or other information from your device. All PDF files you open and read through One PDF remain secure and accessible only by you.
            </Paragraph>
          </Section>
          <Section>
            <Paragraph className="font14 extraBold">4. Changes to the Privacy Policy</Paragraph>
            <Paragraph className="font14">
              We may update this Privacy Policy from time to time. Any changes will be notified through app updates or direct notification to you. We encourage you to review this Privacy Policy periodically to stay informed about how we protect your information.
            </Paragraph>
          </Section>
          <Section>
            <Paragraph className="font14 extraBold">5. Contact Us</Paragraph>
            <Paragraph className="font14">
              If you have any questions or concerns about this Privacy Policy, please contact us at lawang.mamminasa@gmail.com.
            </Paragraph>
            <Paragraph className="font14">
              By using the One PDF app, you agree to this Privacy Policy and the use of permissions as described above.
            </Paragraph>
            <Paragraph className="font14">
              Thank you for using One PDF!
            </Paragraph>
            <Paragraph>
              <strong>Mamminasa Tech</strong><br />
              JL. Kedoya RT 04 / RW 02 Nomor 25 Pondok Cina, Depok.<br />
              lawang.mamminasa@gmail.com
            </Paragraph>
          </Section>
          </div>
        </Content>
      </Container>
      <FooterContainer>
        <FooterText>&copy; {new Date().getFullYear()} Mamminasa Tech. All rights reserved.</FooterText>
        <FooterText>
          Contact us at <FooterLink href="mailto:lawang.mamminasa@gmail.com">lawang.mamminasa@gmail.com</FooterLink>
        </FooterText>
    </FooterContainer>
    </React.Fragment>
  )
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
    margin: auto;
`;

const Title = styled.h1`
    font-size: 2em;
    margin-bottom: 20px;
`;

const Content = styled.div`
    padding: "60px 0"
`;

const Section = styled.div`
    margin-bottom: 20px;
`;

const Paragraph = styled.p`
  line-height: 1.6;
`;

const FooterContainer = styled.footer`
  background-color: #f8f8f8;
  padding: 20px 0;
  text-align: center;
  color: #333;
  border-top: 1px solid #e7e7e7;
  width: 100%;
  bottom: 0;
`;

const FooterText = styled.p`
  margin: 0;
`;

const FooterLink = styled.a`
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default Toc;