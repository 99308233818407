import React from "react";
import styled from "styled-components";
import FullButton from "../../../../components/Buttons/FullButton";
import onePdf from '../../../../assets/img/products/one-pdf.svg'
import Dots from "../../../../assets/svg/Dots";
import HeaderImage from "../../../../assets/img/onepdf-img.png";


const HeaderProduct = () => {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <ProductName className="row">
            <Logo src={onePdf} alt="logo" />
            <h1 className="extraBold font60">One PDF</h1>
          </ProductName>
          <HeaderP className="font20 semiBold">
          With a clean and intuitive interface, this app makes it easy to read, convert images to PDF, compress, merge, and secure your PDFs—all from your mobile device.
          </HeaderP>
          <BtnWrapper>
            <FullButton 
              title="Play Store"
              color="#ff8383"
              hoverColor= "#FF6666"
              action={() => window.open('https://play.google.com/store/apps/details?id=com.onepdf', '_blank')}
            />
          </BtnWrapper>
        </div>
      </LeftSide>

      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="office" style={{zIndex: 9}} />
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>

    </Wrapper>
  )
};

const Wrapper = styled.section`
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const ProductName = styled.div`
  align-items: center;
  @media (max-width: 960px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  margin: 0 -20px;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;

const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const Logo = styled.img`
  width: 100px;
  margin-right: 20px;
  @media (max-width: 560px) {
    width: 20%;
    height: auto;
  }
`;

const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 100px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  width: 60%;
  height: auto;
  @media (max-width: 560px) {
    width: 60%;
    height: auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;

export default HeaderProduct;