import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer"

import INFO from "../data/user";
import SEO from "../data/seo";

import styled from "styled-components";
import AllProducts from "../components/Elements/AllProducts";
import WhatsappWidget from "../components/Elements/WhatsappWidget";

const Products = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "products");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Produk | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>
			<Wrapper id="products">
				<TopNavbar page={"products"} />
				<Body className="whiteBgt">
					<div className="container">
						<h1 className="font40 extraBold">Produk Digital Mamminasa Tech </h1>
						<p className="font15">
						Mamminasa Tech menawarkan berbagai produk digital yang bisa di unduh di Play Store dan App Store.
						<br/>Selain itu, Mamminsa Tech juga menyediakan jasa pengembangan website dan aplikasi mobile
						<br/>di Indonesia, khususnya di Makassar, Sulawesi Selatan. Jelajahi koleksi aplikasi kami yang inovatif serta
						<br/>layanan pengembangan yang profesional dan terpercaya. Dapatkan solusi digital yang dirancang untuk memenuhi
						<br/>kebutuhan bisnis Anda dengan performa optimal dan fitur-fitur terkini.
						<br/>Mulai unduhan produk digital atau konsultasi jasa pengembangan Anda sekarang di Mamminsatech.
						</p>
						<AllProducts />
					</div>
				</Body>
				<Footer page={"products"}/>
				<WhatsappWidget />
			</Wrapper>
		</React.Fragment>
	);
};

const Wrapper = styled.section`
	padding-top: 100px;
  width: 100%;
`;

const Body = styled.div`
	margin-bottom: 70px;
`;
export default Products;
