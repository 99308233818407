import React, { useState } from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import WhatsappWidget from "../components/Elements/WhatsappWidget";
import Products from "../components/Sections/Products";
import { Helmet } from "react-helmet";
import INFO from "../data/user";
import SEO from "../data/seo";

export default function Landing() {
  const currentSEO = SEO.find((item) => item.page === "home");

  return (
    <>
      <Helmet >
				<title>{INFO.main.title}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>
      <TopNavbar page="home"/>
      <Header/>
      <Services />
      <Products />
      {/* <Projects /> */}
      {/* <Blog /> */}
      {/* <Pricing /> */}
      <Contact />
      <Footer page="home"/>
      <WhatsappWidget />
    </>
  );
}


