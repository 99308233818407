import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer"

import INFO from "../data/user";
import SEO from "../data/seo";

import ServicesSection from "../components/Sections/Services";
import WhatsappWidget from "../components/Elements/WhatsappWidget";

const Services = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "services");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Layanan | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>
      <TopNavbar page={"services"} />
      <Wrapper id="services">
        <ServicesSection page="services"/>
      </Wrapper>
      <Footer page={"services"}/>
      <WhatsappWidget />
		</React.Fragment>
	);
};

const Wrapper = styled.div`
  width: 100%;
  padding-top: 50px;
`;
export default Services;
