import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border, color =  "#7620ff", hoverColor = "#580cd2" }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
      color={color}
      hoverColor={hoverColor}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : props.color)};
  background-color: ${(props) => (props.border ? "transparent" : props.color)};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : props.hoverColor)};
    border: 1px solid transparent;
    color: ${(props) => (props.border ? props.color : "#fff")};
  }
`;

