import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";

export default function Footer() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="lightBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
            <div className="column">
              <p className="extraBold font30">One PDF</p>
              <Pby className="font11">By Mamminasa Tech</Pby>
            </div>
            </Link>
            <StyleP className="font13">
              © {getCurrentYear()} - <RouterLink to={"/"}><span className="purpleColor font13">Mamminasa Tech</span></RouterLink> All Right Reserved
            </StyleP>

            <Link className="animate pointer font13" to="home" smooth={true} offset={-80}>
              Back to top
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
const Logo = styled.div`
  align-items: center;
  @media (max-width: 560px) {
    justify-content: center;
  }
`;

const Pby = styled.p`
  color: #1363DF;
  margin: -15px 0 0 20px;
`;