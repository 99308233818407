
import React from "react";

import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

const WhatsappWidget = () => {
	return (
		<WhatsAppWidget
			phoneNo="6285881683688"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={false}
			autoOpenTimer={5000}
			messageBox={true}
			messageBoxTxt=""
			iconSize="55"
			iconColor="white"
			iconBgColor="#25d366"
			headerIcon="../mamminasa-tech-512.png"
			headerIconColor="pink"
			headerTxtColor="black"
			headerBgColor="#25d366"
			headerTitle="Mamminasa Team"
			headerCaption="Online"
			bodyBgColor="#bbb"
			chatPersonName="Support"
			chatMessage={<>Hallo 👋 <br /><br /> Apa yang bisa saya bantu?</>}
			footerBgColor="#999"
			placeholder="Ketik pesan..."
			btnBgColor="#25d366"
			btnTxt="Mulai Chat"
			btnTxtColor="white"
		/>
	);
};

export default WhatsappWidget;