import React from "react";

import INFO from "../../data/user";

import styled from "styled-components";
import Product from "./Product";

const AllProducts = () => {
	return (
		<Wrapper className="row">
			{INFO.products.map((project, index) => (
				<ItemProduct key={index}>
					<Product
						logo={project.logo}
						title={project.title}
						description={project.description}
						linkText={project.linkText}
						link={project.link}
					/>
				</ItemProduct>
			))}
		</Wrapper>
	);
};

const Wrapper = styled.div`
  display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	padding-top: 30px;
	padding-bottom: 30px;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

const ItemProduct = styled.div`
  width: 33%;
	box-sizing: border-box;
	padding: 10px;
	overflow: hidden;

	@media (max-width: 860px) {
		width: 100%;
  }
}
`;

export default AllProducts;
